import React, { useEffect } from 'react';
import loadable from '@loadable/component';
import './style.scss';

// const BlogAuthor = loadable(() => import('./BlogAuthor'))
const PromotionalBlock = loadable(() => import('./PromotionalBlock'));
const TextBlock = loadable(() => import('./TextBlock'));
const ImageBlock = loadable(() => import('./ImageBlock'));

const SliceZone = React.memo(({ sliceZone, filter, expired }) => {
  const sliceComponents = {
    promotions_block: PromotionalBlock,
    image_block: ImageBlock,
    t_c_block: TextBlock,
  };

  return sliceZone.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type];
    if (SliceComponent) {
      if (filter && filter !== '') {
        if (
          (slice.slice_type === 'promotions_block' && filter === slice?.primary?.category?.uid) ||
          slice.slice_type === 't_c_block'
        )
          return <SliceComponent key={index} slice={slice} expired={expired} />;
      } else {
        return <SliceComponent key={index} slice={slice} expired={expired} />;
      }
    }
  });
});

export default SliceZone;
